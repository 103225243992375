@import 'styles/includes.scss';

.SubNavigation {
    $root: &;
    position: relative;
    background-color: $colorBlack800;

    &--Fixed {
        position: fixed;
        top: 0;
        z-index: 3;
        width: 100%;
        padding-top: 1.8rem;
        padding-bottom: 0.2rem;

        box-shadow: 0 0 32px 0 rgba(151, 151, 151, 0.2);

        & + div {
            padding-top: 122px;
        }

        @include media(ml) {
            padding-top: 1.2rem;
        }

        #{$root}__Title {
            font-size: 1.9rem;
            padding-bottom: 1.2rem;
        }
    }

    &__Grid {
        @extend %container;
        position: relative;

        #{$root}--Fixed & {
            height: 3.7rem;

            @include media(ml) {
                height: 4rem;
            }
        }
    }

    &__Container {
        max-width: 100%;
        overflow-x: auto;
        height: 5.5rem;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;
        white-space: nowrap;


        &::-webkit-scrollbar {
            display: none;
        }

        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            width: 6rem;
            height: 100%;
            z-index: 2;

            background: linear-gradient(
                to right,
                rgba(24, 24, 24, 0),
                rgba(24, 24, 24, 0.58),
                $colorBlack800
            );
        }
    }

    &__Title {
        @extend %container;
        @extend %h1;
        font-family: $fontBrand;
        color: $colorWhite;
        padding-bottom: 2rem;

        transition: font-size 0.3s, padding-bottom 0.3s;
    }

    &__List {
        display: inline-flex;
        align-items: center;

        @include media(ml) {
            padding-left: 1.2rem;
        }
    }

    &__ItemLink {
        text-decoration: none;
        font-family: $fontBrand;
        font-size: 1.4rem;
        letter-spacing: 0.02rem;
        padding-bottom: 1.7rem;
        color: $colorWhite;
        margin-right: 3.5rem;
        position: relative;

        &:after {
            background: $colorPink;
            content: '';
            height: 4px;
            position: absolute;
            bottom: 0;
            right: 0px !important;
            left: auto !important;
            width: 0%;
            -webkit-transition: width 400ms cubic-bezier(0.79, 0.14, 0.16, 0.86);
            transition: width 400ms cubic-bezier(0.79, 0.14, 0.16, 0.86);
        }

        @include media(m) {
            margin-right: 5rem;
        }

        @include media(ml) {
            font-size: 1.7rem;
        }

        @include media(xl) {
            font-size: 1.8rem;
            padding-bottom: 1.5rem;
            margin-right: 4.8rem;
        }

        &:not(&--Active):hover {
            &:after {
                left: 0px !important;
                height: 4px;
                right: auto;
                width: 100%;
            }
        }

        &--Active:after {
            left: 0px !important;
            height: 4px;
            right: auto;
            width: 100%;
        }
    }

    &__ItemLabel {
        display: inline;
    }

    &--Overflow {
        #{$root}__Icon--More {
            padding: 15px;

            &__Arrow {
                position: absolute;
                z-index: 3;
                width: 8px;
                height: 8px;
                right: 16px;
                top: 11px;
                border: solid #fff;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;

                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
            }
        }
    }
}
